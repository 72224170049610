body {
  margin: 0;
  max-width: 100%;
}

.App {
  max-width: 100%;
  height: 100vh;
  font-family: 'DM Sans', sans-serif;
}
