.Post {
  width: 95%;
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border: 1px black solid;
  margin-bottom: 15px;
  margin-top: 10px;
  background: #fff;
  top: 150px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.title {
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  color: #000;
}

.date {
  margin: auto;
  font-family: DM Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #000;
}

@media screen and (max-width: 840px) {
  .Post {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    left: 25%;
    right: 25%;
  }

  .date {
    left: 25%;
    right: 25%;
  }
}
