.Footer {
  width: 100%;
  height: 18%;
  background-color: #f8f4ed;
}

.Footer p {
  margin: 0;
}

.Footer p:last-child {
  font-size: 10px;
}

.Footer a {
  color: black;
  text-decoration: none;
}

.Footer img {
  max-height: 10px;
}

@media screen and (max-width: 840px) {
  .Footer {
    position: static;
    display: flex;
    padding: 0;
  }

  .Footer > div {
    flex: 7;
  }
}
