h1 {
  margin: 2%;
}

h2,
p {
  margin: 0;
}

.row {
  margin: 0;
}

.header {
  display: inline-block;
  position: relative;
  background-color: #f8f4ed;
  background-size: 100%;
  width: 100%;
}

.header-content {
  margin-top: 0%;
  width: 100%;
}

.title {
  font-size: 64px;
  background-color: #f8f4ed;
  text-align: center;
}

.love {
  color: #ff8a8a;
}

.subtitle {
  font-size: 24px;
  text-align: center;
}

.samwiselink {
  text-decoration: none;
  color: #7fbdff;
}

.samwise-bear {
  width: 12%;
  margin-left: 10%;
}

@media screen and (max-width: 840px) {
  .title {
    font-size: 34px;
    line-height: 40px;
    margin-top: 10%;
  }

  .subtitle {
    font-size: 12px;
  }

  .samwise-bear {
    width: 8%;
    margin-left: 5%;
  }
}
