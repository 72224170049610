h2,
p {
  margin: 0;
}

.progress {
  display: center;
  height: auto;
  width: 75em;
  margin-top: 5%;
  padding-left: 0;
  padding-right: 0;
}

.section-heading {
  margin-top: 40px;
  font-size: 32px;
}

.date-heading {
  padding-bottom: 10px;
}

.message-heading {
  padding-right: 75px;
  padding-left: 75px;
  padding-bottom: 20px;
  font-size: 16px;
}

.image-heading {
  width: 95%;
  margin-left: 2.5%;
}

.shoutout-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.article-title {
  margin-top: 20px;
  font-size: 18px;
  padding-right: 50px;
  padding-left: 50px;
  text-align: left;
}

.articles {
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 16px;
  padding-right: 50px;
  padding-left: 50px;
  text-align: left;
  width: 85%;
}

.productivity-title {
  font-size: 24px;
}

.feature-message {
  margin-top: 5px;
  text-align: left;
  font-size: 16px;
  padding-right: 50px;
  padding-left: 50px;
  width: 85%;
}

@media screen and (max-width: 1275px) {
  .section-heading {
    font-size: 22px;
    line-height: 40px;
    margin-top: 10%;
  }

  .message-heading {
    padding-right: 55px;
    padding-left: 45px;
    padding-bottom: 20px;
    font-size: 17px;
    width: 60%;
  }

  .image-heading {
    width: 60%;
    margin-left: 1%;
    margin-right: auto;
  }

  .shoutout-image {
    display: block;
    margin-left: 3%;
    margin-right: 5%;
    width: 60%;
  }

  .article-title {
    margin-top: 20px;
    width: 70%;
    font-size: 18px;
    padding-right: 50px;
    padding-left: 50px;
    text-align: left;
  }

  .articles {
    width: 60%;
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
    text-align: left;
  }

  .feature-message {
    margin-top: 5px;
    text-align: left;
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
    width: 60%;
  }
}

@media screen and (max-width: 840px) {
  .section-heading {
    font-size: 22px;
    line-height: 40px;
    margin-top: 10%;
  }

  .subtitle {
    font-size: 12px;
  }

  .samwise-bear {
    width: 8%;
    margin-left: 5%;
  }

  .progress {
    display: center;
    height: auto;
    width: 35em;
    margin-top: 5%;
    padding-left: 0;
    padding-right: 0;
  }

  .date-heading {
    padding-bottom: 10px;
  }

  .message-heading {
    padding-right: 55px;
    padding-left: 45px;
    padding-bottom: 20px;
    font-size: 17px;
    width: 45%;
  }

  .image-heading {
    width: 50%;
    margin-left: 3%;
    margin-right: auto;
  }

  .shoutout-image {
    display: block;
    margin-left: 3%;
    margin-right: 5%;
    width: 50%;
  }

  .article-title {
    margin-top: 20px;
    width: 50%;
    font-size: 18px;
    padding-right: 50px;
    padding-left: 50px;
    text-align: left;
  }

  .articles {
    width: 45%;
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
    text-align: left;
  }

  .feature-message {
    margin-top: 5px;
    text-align: left;
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
    width: 45%;
  }
}
