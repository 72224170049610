.SignUp {
  width: 100%;
  height: auto;
  display: flex;
}

.Image {
  width: 75%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px black solid;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.PromptTitle {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.PromptText {
  text-align: center;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.PromptBox {
  border-radius: 1px;
  margin-bottom: 10px;
  height: auto;
}

.PromptBoxInside {
  flex: 1;
  height: 100px;
  width: 100%;
  margin-bottom: 1px;
  border: 1px peachpuff solid;
  border-radius: 10px;
}

input,
input::placeholder {
  font-size: 20px;
  opacity: 1;
  color: grey;
  padding-left: 10px;
}

.Button {
  background: #f4ab98;
  border-radius: 10px;
  width: 101%;
  min-width: fit-content;
  font-size: 25px;
  line-height: 62px;
  box-shadow: 0 0 30px #f5cfbf;
  font-weight: bold;
  z-index: 10;
  border: none;
  margin-top: 10px;
  margin-bottom: 80px;
}

.Left {
  width: 100%;
  height: auto;
  background-color: #f5cfbf;
  padding: 0 10%;
  box-sizing: border-box;
}

.Phone {
  position: absolute;
  left: 50%;
  max-width: 40%;
}

.Phone2 {
  display: none;
}

.Button:hover,
.Button:focus {
  background-color: #ffd1c5;
  cursor: pointer;
  border: 0.5px black solid;
}

@media screen and (max-width: 840px) {
  .SignUp {
    display: block;
    padding-bottom: 0;
  }

  .Image {
    width: 105%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px black solid;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .Left {
    padding: 1em 10% 0.1em 10%;
    height: auto;
    width: 100%;
  }

  .Phone2 {
    display: block;
    max-width: 100%;
    padding: 3em 0;
    margin: 0 auto;
    max-height: 60%;
  }
}
