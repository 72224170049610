.Landing {
  background-color: #f8f4ed;
  width: 100%;
  height: auto;
}

.LogoBar {
  width: auto;
  padding: 1em 0 1em 2em;
  box-sizing: border-box;
  background-color: white;
  height: auto;
}

.Title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  margin: 0 0 0 0;
  z-index: 10;
  position: relative;
}

.NavBarItems {
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  margin: 0 7% 0 0;
}

.NavBarTitle {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 40px;
  text-align: left;
  margin: 0 0 0 0;
  position: relative;
}

a {
  color: black;
  text-decoration: none;
}

.Love {
  color: #ff8a8a;
}

.SubText {
  font-weight: bold;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 36px;
  text-align: center;
  width: auto;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  padding: 10px 10px 20px 10px;
}

.NavBarSubscribe {
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  position: relative;
  margin: 0 7% 0 0;
  font-weight: bold;
  background: #f4ab98;
  border-radius: 7px;
  width: 140px;
  min-width: fit-content;
  text-align: center;
}

.Button {
  background: #f4ab98;
  border-radius: 40px;
  width: 20%;
  min-width: fit-content;
  font-size: 25px;
  line-height: 42px;
  box-shadow: 0 0 30px #f5cfbf;
  font-weight: bold;
  position: absolute;
  left: 30%;
  bottom: 20%;
  z-index: 10;
  border: none;
}

.Button:hover,
.Button:focus {
  background-color: #ffd1c5;
  cursor: pointer;
  border: 0.5px black solid;
}

@media screen and (max-width: 840px) {
  .Logo {
    width: 50%;
  }

  .LogoBar {
    width: auto;
    padding: 0.5em 0 2.6em 1em;
    box-sizing: border-box;
    background-color: white;
    height: auto;
  }

  .Title {
    font-size: 32px;
    line-height: 40px;
    margin-top: 10%;
  }

  .SubText {
    font-size: 20px;
    line-height: 24px;
  }

  .Button {
    position: relative;
    width: 50%;
    margin: 1em -10%;
    border: none;
    left: 35%;
  }
}
