.Blog {
  background-color: #f8f4ed;
  width: auto;
  height: auto;
  display: block;
  font-family: 'DM Sans', sans-serif;
}

.List {
  padding: 20px;
}

.heading {
  position: relative;
  width: 50%;
  height: 0;
  left: 150px;
  margin-bottom: 10%;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 94px;
  color: #000;
}

a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 840px) {
  .Blog {
    width: 100%;
    height: 100%;
  }

  .List {
    width: auto;
  }

  .Logo {
    width: 50%;
  }

  .LogoBar {
    width: auto;
    padding: 0.5em 0 2.6em 1em;
    box-sizing: border-box;
    background-color: white;
    height: auto;
  }

  .heading {
    width: 80%;
    margin-left: -90px;
    margin-bottom: 60px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 64px;
    color: #000;
  }

  .Title {
    font-size: 32px;
    line-height: 40px;
    margin-top: 10%;
  }

  .SubText {
    font-size: 20px;
    line-height: 24px;
  }

  .Button {
    position: relative;
    width: 50%;
    margin: 1em -10%;
    border: none;
    left: 35%;
  }
}
