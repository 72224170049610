.EmailBox {
  width: 130%;
  position: relative;
  z-index: 2;
}

.Input {
  width: 50%;
  font-size: 20px;
  font-family: 'DM Sans', sans-serif;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  border: 1px solid #fff;
  padding-right: 25%;
  padding-left: 20px;
}

.Button {
  width: 25%;
  height: 45px;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  border-radius: 20px;
  background: #f4ab98;
  margin-left: -25%;
  padding: 1px;
  position: relative;
  bottom: 2px;
  border: none;
}

.Button:hover,
.Button:focus {
  background-color: #ffd1c5;
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .EmailBox {
    width: 90%;
    margin: 0 auto;
    margin-right: 10%;
  }

  .Input {
    width: 90%;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    height: 30px;
    padding-right: 20%;
    padding-left: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  }

  .Button {
    width: 100%;
    left: 8%;
    margin-top: 5%;
    margin-left: auto;
    font-size: 12px;
    line-height: 20px;
    height: 32px;
    padding-top: 2px;
    margin-right: -10%;
    border: 2px solid #f4ab98;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
    bottom: 1px;
  }

  .Result {
    position: absolute;
  }
}
