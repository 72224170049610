h1 {
  margin: 2%;
}

h2,
p {
  margin: 0;
}

.row {
  margin: 0;
}

.footer {
  margin-top: 40px;
  background-color: #f8f4ed;
  border-radius: 20px;
}

.footer-content {
  margin-top: 0%;
  width: 95%;
  padding: 50px;
}

.mission {
  font-size: 20px;
  background-color: #f8f4ed;
  text-align: center;
  padding-bottom: 2%;
}

.samwise {
  font-size: 20px;
  margin-left: 2%;
  margin-right: 2%;
}

.samwise-logo {
  display: block;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media screen and (max-width: 1275px) {
  .mission {
    font-size: 16px;
    background-color: #f8f4ed;
    padding-bottom: 4%;
    width: 95%;
    text-align: left !important;
  }

  .samwise {
    font-size: 16px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 95%;
    text-align: left !important;
  }

  .samwise-logo {
    display: block;
    padding-top: 5%;
    margin-left: 10%;
    margin-right: auto;
    width: 70%;
  }
}

@media screen and (max-width: 840px) {
  .mission {
    font-size: 16px;
    background-color: #f8f4ed;
    padding-bottom: 4%;
    width: 80%;
  }

  .samwise {
    font-size: 16px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 5%;
    width: 85%;
  }

  .samwise-logo {
    display: block;
    padding-top: 5%;
    margin-left: 10%;
    margin-right: auto;
    width: 50%;
  }
}
